<script lang="typescript">
  import type { IRecommandation } from '../models';

  export let recommandation: IRecommandation;
  export let className = '';
  export let href = '';

  const numberFormat = new Intl.NumberFormat('fr-FR');
  const currencyFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });

  function getYearAndMileage(recommandation: IRecommandation): string {
    let parts = [];

    if (recommandation.year) {
      parts.push(recommandation.year);
    }
    if (recommandation.mileage) {
      parts.push(numberFormat.format(recommandation.mileage) + 'km');
    }
    return parts.join(' - ')
  }
</script>

<!-- <svelte:options tag="tgc-recommandation" /> -->

<a class="recommandation-container {className}" {href}>

  <div class="recommandation-model">
    <span class="tag white">{recommandation.model}</span>
  </div>

  <div class="recommandation-img" style="background-image: url('{recommandation.image}')"></div>

  <div class="recommandation-content">
    {#if recommandation.motor}
    <div class="recommandation-motor">
      <span class="tag black">{recommandation.motor}</span>
    </div>
    {/if}
    {#if recommandation.warranty}
    <div class="recommandation-warranty">
      <span class="tag black">Garantie {recommandation.warranty}</span>
    </div>
    {/if}
    <div>
      <span class="tag black">{getYearAndMileage(recommandation)}</span>
    </div>


    <div class="recommandation-equipments">
      {#each recommandation.equipments as equipment}
      <div class="recommandation-equipment">
        <span class="tag orange">▲</span>
        <span class="equipment">
          <span class="tag orange">{equipment}</span>
        </span>
      </div>
      {/each}
    </div>

    {#if recommandation.price}
    <div class="recommandation-price">{currencyFormat.format(recommandation.price)}&#42;</div>
    {/if}
  </div>
</a>

<style>
  a {
    text-decoration: none;
  }
  .recommandation-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    font-weight: bold;
  }
  .recommandation-container:nth-child(2n) {
    background: #887f78;
  }
  .recommandation-container:nth-child(2n + 1) {
    background: #93928d;
  }
  .recommandation-model {
    padding: 10px 5px;
    height: 45px;
    font-size: 18px;
  }
  .tag {
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    padding: 0 3px;
    border-radius: 3px;
    white-space: initial;
  }
  .tag.white {
    background: white;
    color: black;
  }
  .tag.black {
    background: black;
    color: white;
  }
  .tag.orange {
    background: #ff8302;
    color: white;
  }
  .equipment {
    display: inline-block;
    max-width: 200px;
  }
  .recommandation-model span {
    text-transform: uppercase;
  }
  .recommandation-img {
    width: 250px;
    height: 150px;
    background-size: cover;
    background-position: center;
  }
  .recommandation-content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
  }
  .recommandation-equipments {
    color: white;
    text-align: left;
    padding-left: 15px;
    font-size: 14px;
  }
  .recommandation-equipment {
    margin-top: 8px;
  }
  .recommandation-equipment > span:first-of-type {
    vertical-align: top;
  }
  .recommandation-price {
    margin-top: 10px;
    font-size: 25px;
    color: white;
  }
</style>
