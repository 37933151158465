<script lang="ts">
  import Recommandation from './components/Recommandation.svelte';
  import KeenSlider from 'keen-slider';
  import 'keen-slider/keen-slider.min.css';
  import { onMount,tick } from 'svelte';
  import type { IRecommandation } from './models';

  let recommandations: IRecommandation[] = [];
  let sliderRef: HTMLElement;
  let slider: KeenSlider;
  let intervalId: number;

  let canNext = false;
  let canPrev = false;

  let pathname = window.location.pathname.slice(1);
  let href = pathname
    ? '/diagnostic?franchise=' + pathname
    : '/diagnostic'

  onMount(async () => {
    const res = await fetch(`${BASE_API_URL}/api/recommandations`);
    recommandations = await res.json();

    await tick();

    slider = new KeenSlider(sliderRef, {
      controls: true,
      slidesPerView: () => sliderRef.clientWidth / 250,
      duration: 1000,
      loop: true,
      dragStart: () => stopAutoplay(),
      dragEnd: () => startAutoplay(),
      afterChange: instance => {
        const { relativeSlide, slidesPerView, size } = instance.details();

        canPrev = relativeSlide > 0;
        canNext = relativeSlide + slidesPerView < size;
      },
      mode: "free-snap",
    });

    startAutoplay();
  });

  function startAutoplay() {
    if (!intervalId) {
      intervalId = setInterval(() => {
        slider.next();
      }, 4000)
    }
  }

  function stopAutoplay() {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  }

</script>

<div class="recommandations-container keen-slider" bind:this={sliderRef} on:mouseleave={startAutoplay} on:mouseenter={stopAutoplay} >
  {#each recommandations as recommandation}
  <Recommandation className="keen-slider__slide" {recommandation} {href} />
  {/each}

  {#if canPrev}
  <svg
    class="arrow arrow--left"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    on:click={() => slider.prev()}
    >
    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path>
  </svg>
  {/if}
  {#if canNext}
  <svg
    class="arrow arrow--right"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    on:click={() => slider.next()}
    >
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
  </svg>
{/if}
</div>


<style>
  .recommandations-container {
    height: 450px;
    line-height: normal;
    font-size: 16px;
  }

  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
  }

  .arrow--left {
    left: 5px;
  }

  .arrow--right {
    left: auto;
    right: 5px;
  }
</style>
